import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";

export enum LogType {
    INFO = "INFO",
    ERROR = "ERROR",
    SYNCHRONIZE = "SYNCHRONIZE",
}

@JsonConverter
class LogConverter implements JsonCustomConvert<LogType> {

    deserialize(data: string): LogType { return (<any>LogType)[data] }
    serialize(data: LogType): any { return data.toString() }
}

@JsonObject("Log")
export default class Log extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("log", String, true)
    log?: string = undefined
    @JsonProperty("type", LogConverter, true)
    type?: LogType = undefined
    @JsonProperty("subject", String, true)
    subject?: string = undefined

}