import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"
import Synchro, {SynchroType} from "@/models/Synchro"
import axios, {AxiosError, AxiosResponse} from "axios"
import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source'
import Product from "@/models/Product";
import Response from "@/models/responses/Response";

export default class SynchroService {

    static async getSynchro(id: number): Promise<Response<Synchro>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/synchro/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const synchro = JsonTool.jsonConvert.deserializeObject(response.data, Synchro)
            return Promise.resolve({ result: synchro })
        } catch (e) { return Promise.reject(e) }
    }

    static async getSynchros(component: Vue, synchros: Synchro[], page: number, size: number, synchroType: SynchroType | null) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/synchros", {
                params: { page, size, synchroType },
                headers: {Authorization: getModule(SessionModule).session.token,}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Synchro)
            synchros.splice(0, synchros.length)
            list.forEach(p => synchros.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener las synchros");
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async synchronizeProducts(component: Vue) {

        // @ts-ignore
        component.loading = true
        try {

            await fetchEventSource(ConstantTool.BASE_URL + "/api/synchronize/products", {
                method: 'PATCH',
                headers: {
                    'Authorization': getModule(SessionModule).session.token,
                    'Content-Type': 'application/stream+json'
                },
                onmessage(ev) {
                    let response = JSON.parse(ev.data)
                    console.log(response)
                    // @ts-ignore
                    component.progress = response.percentage

                    if (response.percentage == 100) {
                        getModule(SnackbarModule).makeToast("Los productos se han actualizado, los cambios podrían tardar algunos segundos en mostrarse.")
                    }
                },
                onerror(ev) {
                    console.log(ev)
                    getModule(SnackbarModule).makeToast("Ha ocurrido un error mientras se actualizaban los productos.")
                    throw (ev)
                }
            })
        } catch (err) {
            console.log(err)
            getModule(SnackbarModule).makeToast("Error al sincronizar las empresas")
        } finally {
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            await component.refresh()
        }
    }

    static async synchronizeCompany(connectorId: string) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/synchronize/company/${connectorId}`, null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })

            if (response.status == 200) {
                getModule(SnackbarModule).makeToast(`La compania termino de sincronizarse. Consulte los logs para mas detalles.`)
            }

            return Promise.resolve({ result: null })

        } catch (e) { return Promise.reject(e) }
    }

    static async pingXGEST() {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/synchronize/ping", {
                headers: { Authorization: getModule(SessionModule).session.token }
            })

            if (response.status == 200) { getModule(SnackbarModule).makeToast("Ping exitoso") }
            else { getModule(SnackbarModule).makeToast(`Ping Fallido: ERROR ${response.status}`) }
        } catch (e) { return Promise.reject(e) }
    }

    static async synchronizeCompanies() {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/synchronize/company", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            getModule(SnackbarModule).makeToast("Las empresas se han empezado a sincronizar, este proceso podría tardar algunos minutos.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al sincronizar las empresas")
            return Promise.reject(e)
        }
    }

    static async synchronizeAllProducts() {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/synchronize/products", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            getModule(SnackbarModule).makeToast("Los productos se han empezado a sincronizar, este proceso podría tardar algunos minutos.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Error al sincronizar los productos.")
            return Promise.reject(e)
        }
    }
}