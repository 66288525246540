













































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import User from "@/models/User";
import Options from "@/models/vue/Options";
import UserService from "@/services/UserService";
import Company from "@/models/Company";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import LangModule from "@/store/LangModule";
import DistributorService from "@/services/user/DistributorService";
import PaginationMixin from "@/mixins/PaginationMixin";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";

@Component
export default class DistributorsTab extends Mixins(PaginationMixin) {
    lang: any = getModule(LangModule).lang
    @Prop() readonly company!: Company
    users: MultipleItem<User> = { items: [], totalItems: 0 }
    loading: boolean = false
    options: Options = new Options()
    headers = [
        { align: "center", text: "Id", value: "id", width: "50px" },
        { align: "center", text: this.lang.email, value: "email", width: "auto" },
        // { align: "center", text: this.lang.name, value: "name", width: "auto" },
        { align: "center", text: this.lang.commercialName, value: "commercialName", width: "auto" },
        { align: "center", text: this.lang.status, value: "active", width: "auto" },
        { align: "center", text: "Fuente", value: "source", width: "100px" },
    ]

    created() { this.watchOptions() }

    @Watch("options")
    async watchOptions() {
        await Handler.getItems(this, this.users, () => DistributorService.getDistributorsByCompanyId(this.company.id!, this.page - 1, this.size, this.search))
        this.setPageCount(this.users.totalItems!!)
    }

    rowClick(user: User) {
        if (user.id === getModule(SessionModule).session.user.id) {
            this.$router.push({path: "/profile"});
            return
        }
        this.$router.push({path: "/distributors/" + user.id});
    }
}
