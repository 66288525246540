


































































































import {Component, Vue} from "vue-property-decorator";
import Company from "@/models/Company";
import CompanyService from "@/services/CompanyService";
import CompanyTab from "@/components/tabs/CompanyTab.vue";
import DistributorsTab from "@/components/tabs/DistributorsTab.vue";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import ConfigModule from "@/store/ConfigModule";
import SynchroService from "@/services/SynchroService";
import Handler from "@/handlers/Handler";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";

@Component({components: {CompanyTab, DistributorsTab}})
export default class CompanyView extends Vue {

    lang: any = getModule(LangModule).lang
    loading: boolean = false
    company: Company = new Company()
    tabs: string[] = ["/orders", "/distributors"]
    tab: number = 0

    get configs() {
        return getModule(ConfigModule).configs
    }

    created() {
        this.refresh()
    }

    refresh() {
        CompanyService.getCompany(this, Number(this.$route.params.id))
    }

    activateCrm() {
        CompanyService.activateCompanyCrm(this, this.company)
    }

    deactivateCrm() {
        CompanyService.deactivateCompanyCrm(this, this.company)
    }

    async synchronize() {
        await Handler.getItem(this, { item: null }, () =>
            SynchroService.synchronizeCompany(this.company.connectorId!!)
        )
    }

}
