import axios from "axios";
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import User from "@/models/User"
import Response from "@/models/responses/Response"
import {Vue} from "vue-property-decorator";
import SnackbarModule from "@/store/SnackbarModule";
import UserService from "@/services/UserService";

export default class DistributorService {

    static async getDistributorsByCompanyId(companyId: number, page: number, size: number, search: Nullable<string>): Promise<Response<User[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/company/${companyId}/distributors/users`, {
                headers: {Authorization: getModule(SessionModule).session.token},
                params: { page, size, search }
            })
            let distributors = JsonTool.jsonConvert.deserializeArray(response.data, User)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: distributors, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async getDistributor(id: number): Promise<Response<User>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/user/distributor/${id}`,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const distributor = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: distributor })
        } catch (e) { return Promise.reject(e) }
    }

    static async setActive(id: number, enabled: boolean): Promise<Response<User>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/user/distributor/${id}/enabled`, null,{
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { enabled }
            })
            const distributor = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: distributor })
        } catch (e) { return Promise.reject(e) }
    }

}